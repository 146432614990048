/* General Styles */
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f7f6;
  color: #333;
  line-height: 1.6;
}

.referral-program {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header Section */
.header {
  text-align: center;
  padding: 5px 20px;
  background: linear-gradient(135deg, #ffffff, #ffffff);
  color: white;
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 700;
}

.header p {
  font-size: 1.1rem;
  opacity: 0.9;
}

/* Steps Section */
.steps {
  text-align: center;
  margin-bottom: 40px;
}

.steps h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2575fc;
}

.steps-grid {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.step {
  background: white;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 30%;
  min-width: 250px;
  margin: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.step-icon {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  color: #2575fc;
  margin-bottom: 15px;
}

/* Referral Link Section */
.referral-link {
  text-align: center;
  margin-bottom: 40px;
}

.referral-link h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2575fc;
}

.link-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  flex-wrap: wrap;
}

.link-container input {
  width: 50%;
  min-width: 250px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.link-container input:focus {
  border-color: #2575fc;
}

.link-container button {
  padding: 12px 25px;
  background-color: #2575fc;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.link-container button.copied {
  background-color: #28a745;
}

.link-container button:hover {
  background-color: #1a5bbf;
}

.social-share {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.social-share button {
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-share button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.social-share .whatsapp {
  background-color: #25d366;
  color: white;
}

.social-share .facebook {
  background-color: #1877f2;
  color: white;
}

.social-share .twitter {
  background-color: #1da1f2;
  color: white;
}

/* Earnings Section */
.earnings {
  text-align: center;
  margin-bottom: 40px;
}

.earnings h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2575fc;
}

.earnings-amount {
  font-size: 3rem;
  font-weight: bold;
  color: #2575fc;
  margin: 20px 0;
}

.earn-button {
  padding: 12px 25px;
  background-color: #2575fc;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.earn-button:hover {
  background-color: #1a5bbf;
}

/* Leaderboard Section */
.leaderboard {
  margin-bottom: 40px;
}

.leaderboard h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2575fc;
}

table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

th, td {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #2575fc;
  color: white;
  font-weight: 600;
}

tr:hover {
  background-color: #f1f1f1;
}


/* Responsive Design */
@media (max-width: 768px) {
  .steps-grid {
    flex-direction: column;
    align-items: center;
  }

  .step {
    width: 80%;
  }

  .link-container input {
    width: 80%;
  }

  .social-share {
    flex-direction: column;
    align-items: center;
  }

  .social-share button {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 2rem;
  }

  .header p {
    font-size: 1rem;
  }

  .steps h2, .referral-link h2, .earnings h2, .leaderboard h2 {
    font-size: 1.5rem;
  }

  .step {
    width: 100%;
  }

  .link-container input {
    width: 100%;
  }

  .social-share button {
    width: 100%;
  }
}