/* General Styles */
.internship-details {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  font-size: 12px; /* Smaller base font size */
}

h1 {
  font-size: 18px; /* Smaller heading */
  text-align: center;
  margin-bottom: 10px;
}

h2 {
  font-size: 16px; /* Smaller subheading */
  margin-top: 10px;
  margin-bottom: 8px;
}

h3 {
  font-size: 14px; /* Smaller sub-subheading */
  margin-top: 8px;
  margin-bottom: 6px;
}

p {
  font-size: 12px; /* Smaller paragraph text */
  line-height: 1.4;
  margin-bottom: 8px;
}

/* Role Selection Dropdown */
.role-selection {
  margin-bottom: 10px;
}

.role-selection label {
  font-size: 12px;
  margin-right: 5px;
}

.role-selection select {
  font-size: 12px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Image */
.internship-detail-image {
  width: 100%;
  max-width: 300px; /* Smaller image */
  height: auto;
  display: block;
  margin: 10px auto;
  border-radius: 8px;
}

/* Program Overview */
.internship-info {
  margin-bottom: 15px;
}

.internship-info ul {
  padding-left: 20px;
  margin-bottom: 10px;
}

.internship-info ul li {
  font-size: 12px;
  margin-bottom: 5px;
}

/* Details Grid */
.details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 10px;
}

.details-grid div {
  font-size: 12px;
}

.stipend {
  color: #28a745;
  font-weight: bold;
}

/* Skills Grid */
.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 8px;
  margin-bottom: 10px;
}

.skill-card {
  background-color: #f8f9fa;
  padding: 6px;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  border: 1px solid #ddd;
}

/* Responsibilities List */
.responsibilities-list {
  padding-left: 20px;
  margin-bottom: 10px;
}

.responsibilities-list li {
  font-size: 12px;
  margin-bottom: 5px;
}

/* Apply Button */
.apply-button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  font-size: 12px;
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 10px;
}

.apply-button:hover {
  background-color: #0056b3;
}

/* Testimonials Section */
.testimonials-section {
  margin-top: 15px;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.testimonial-card {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  text-align: center;
}

.testimonial-image {
  width: 60px; /* Smaller testimonial image */
  height: 60px;
  border-radius: 50%;
  margin-bottom: 6px;
}

.testimonial-text {
  font-size: 12px;
  margin-bottom: 6px;
}

.testimonial-name {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
}

.testimonial-role {
  font-size: 10px;
  color: #666;
}

/* Certificate Sample Section */
.certificate-section {
  margin-top: 15px;
  text-align: center;
}

.certificate-sample {
  width: 100%;
  max-width: 300px; /* Smaller certificate image */
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}
/* Certificate Sample Section */
.offer-section {
  margin-top: 15px;
  text-align: center;
}

.offer-sample {
  width: 100%;
  max-width: 300px; /* Smaller certificate image */
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}