/* General Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

h1, h2 {
  color: #0056b3;
}



/* Contact Us Section */
.contact-container {
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.contact-info {
  margin-bottom: 30px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1.1rem;
}



.contact-item a {
  color: #0056b3;
  text-decoration: none;
}

.contact-item a:hover {
  text-decoration: underline;
}

.contact-item svg {
  color: #0056b3;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-form label {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.contact-form input, .contact-form textarea {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-form input:focus, .contact-form textarea:focus {
  border-color: #0056b3;
  outline: none;
}

.contact-form button {
  padding: 12px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
}

.contact-form button:hover {
  background-color: #004494;
}

/* Social Media Section */
.social-media {
  text-align: center;
  margin-top: 40px;
}

.social-media p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-icon {
  font-size: 2rem;
  color: #333;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #0056b3;
}

.social-icon i {
  transition: transform 0.3s ease;
}

.social-icon:hover i {
  transform: scale(1.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-container {
    padding: 30px 20px;
  }

  .contact-item {
    font-size: 1rem;
  }

  .contact-form {
    padding: 15px;
  }

  .contact-form button {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .contact-form input, .contact-form textarea {
    font-size: 0.9rem;
  }

  .contact-form button {
    font-size: 1rem;
  }

  .social-icons {
    flex-direction: row;
  }

  .social-icon {
    font-size: 1.8rem;
  }
}
