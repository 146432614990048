/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  padding: 20px;
}

/* Container for the courses */
.courses-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Course Section */
.courses h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #2c3e50;
  text-align: center;
}

/* Course Card */
.course-item {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 250px;
  margin: 0 auto;
}

.course-item:hover {
  transform: translateY(-5px);
}

/* Course Image */
.course-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}

/* Course Title and Info */
.course-item h4 {
  font-size: 18px;
  color: #2c3e50;
  margin: 15px 0 10px 0;
  text-align: center;
}

.course-item p {
  font-size: 14px;
  color: #7f8c8d;
  text-align: center;
}

.course-item p:first-child {
  margin-top: 5px;
}

/* Enroll Now Button */
.course-item button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.course-item button:hover {
  background-color: #d10606;
}

/* Modal Styles */
.Modal__Overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.Modal__Content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Modal Form */
form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

form input {
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

form button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

form button:hover {
  background-color: #3c00bf;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .courses-container {
    padding: 10px;
  }

  .courses h2 {
    font-size: 20px;
  }

  .course-item {
    max-width: 200px;
  }

  .course-item h4 {
    font-size: 16px;
  }

  .course-item p {
    font-size: 12px;
  }

  .course-item button {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .course-item {
    max-width: 100%;
    padding: 15px;
  }

  .course-image {
    height: 120px;
  }

  .course-item h4 {
    font-size: 14px;
  }

  .course-item p {
    font-size: 12px;
  }

  .course-item button {
    font-size: 12px;
  }
}



