/* Enroll.module.css */

/* General Styles */
.enrollContainer {
  padding: 40px 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  color: #fff;
}

.enrollContent {
  max-width: 1200px;
  width: 100%;
  text-align: center;
}

.enrollTitle {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.enrollDescription {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #e0e0e0;
}

/* Programs List */
.programsList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 20px;
}

/* Program Card */
.programCard {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.programCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.programImageContainer {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
}

.programImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.programTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
}

.programDescription {
  font-size: 1rem;
  color: #e0e0e0;
  margin-bottom: 15px;
}

.programAvailability {
  font-size: 0.9rem;
  color: #e0e0e0;
  margin-bottom: 20px;
}

.programAvailability span {
  font-weight: 600;
  color: #ff6f61;
}

/* Enroll Button */
.enrollButton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(135deg, #ff6f61 0%, #ff3b3f 100%);
  border-radius: 25px;
  text-decoration: none;
  transition: background 0.3s ease, transform 0.3s ease;
}

.enrollButton:hover {
  background: linear-gradient(135deg, #ff3b3f 0%, #ff6f61 100%);
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .enrollTitle {
    font-size: 2.5rem;
  }

  .enrollDescription {
    font-size: 1rem;
  }

  .programsList {
    grid-template-columns: 1fr;
  }
}