/* General Styles */
.about-us {
  font-family: 'Inter', sans-serif;
  color: #333;
  line-height: 1.6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}


/* Mission and Vision Section */
.mission-vision {
  padding: 80px 0;
  background: #f9f9f9;
}

.mission-vision-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.mission-vision-card {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mission-vision-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.mission-vision-card .icon {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 20px;
}

.mission-vision-card h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #007bff;
}

.mission-vision-card p {
  font-size: 1rem;
  color: #555;
}

/* Values Section */
.values {
  padding: 80px 0;
  background: #fff;
}

.values h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #007bff;
  text-align: center;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.value-card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.value-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.value-card .icon {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 15px;
}

.value-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.value-card p {
  font-size: 1rem;
  color: #555;
}

/* Timeline Section */
.timeline {
  padding: 80px 0;
  background: #f9f9f9;
}

.timeline h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #007bff;
  text-align: center;
}

.timeline-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.timeline-card {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.timeline-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.timeline-year {
  font-size: 1.5rem;
  font-weight: 600;
  color: #007bff;
  margin-right: 20px;
}

.timeline-content h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.timeline-content p {
  font-size: 1rem;
  color: #555;
}

/* Team Section */
.team {
  padding: 80px 0;
  background: #fff;
}

.team h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #007bff;
  text-align: center;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.team-member {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.team-member-photo img {
  width: 60%;
  height: 200px;
  object-fit: cover;
}

.team-member-info {
  padding: 20px;
  text-align: center;
}

.team-member-info h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.team-member-info p {
  font-size: 1rem;
  color: #777;
}

.team-member-bio {
  font-size: 0.9rem;
  color: #555;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .mission-vision-card h2,
  .values h2,
  .timeline h2,
  .team h2,
  .cta h2 {
    font-size: 2rem;
  }

  .team-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}