/* Internship.css */

/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
  color: #333;
}

/* Container for the internship list */
.internship-container {
  padding: 20px 1px;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden; /* Hide overflow to prevent vertical scrollbar */
}

.internship-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 30px;
}

/* Horizontal scrolling list */
.internship-list {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  gap: 20px; /* Space between cards */
  padding-bottom: 20px; /* Add padding to avoid cutting off box-shadow */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 transparent; /* For Firefox */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.internship-list::-webkit-scrollbar {
  height: 8px;
}

.internship-list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.internship-list::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Individual internship card */
.internship-card {
  flex: 0 0 auto; /* Prevent cards from shrinking or growing */
  width: 300px; /* Fixed width for each card */
  background: #fff;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.internship-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.internship-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-content {
  padding: 10px;
}

.internship-heading {
  font-size: 1rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 10px;
}

.course-duration {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

.register-btn {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  text-decoration: none;
}

.register-btn:hover {
  background-color: #004998;
}

.info-corner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f1f1f1;
  border-top: 1px solid #ddd;
}

.info-item {
  font-size: 0.9rem;
  color: #333;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .internship-title {
    font-size: 1.5rem;
  }

  .internship-card {
    width: 250px; /* Smaller cards for mobile */
  }
}


/* Testimonials section */
.testimonials-section {
  margin-top: 50px;
  text-align: center;
}

.testimonial-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #444;
}

.testimonials {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.testimonial-card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 10px;
}

.testimonial-feedback {
  font-style: italic;
  font-size: 0.95rem;
  color: #555;
  margin-bottom: 10px;
}

.testimonial-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}







/* Certificate Section */
.certificate-section {
  padding: 40px 20px;
  background-color: #f9f9f9; /* Light background for the section */
  text-align: center;
}

/* Certificate Title */
.certificate-title {
  font-size: 2rem;
  color: #333333;
  margin-bottom: 20px;
  font-weight: 600;
}

/* Certificate Gallery */
.certificate-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between items */
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* Individual Certificate Card */
.certificate-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px; /* Fixed width for each card */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.certificate-card:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

/* Certificate Image */
.certificate-image {
  width: 100%;
  height: auto;
  display: block;
  border-bottom: 1px solid #e0e0e0; /* Divider between image and text */
}

/* Certificate Title (Inside Card) */
.certificate-card .certificate-title {
  text-align: center;
  font-size: 1.2rem;
  margin: 15px 0;
  color: #333333;
  font-weight: 500;
}

/* Responsive Design */
@media (max-width: 768px) {
  .certificate-gallery {
    flex-direction: column;
    align-items: center;
  }

  .certificate-card {
    width: 100%; /* Full width on smaller screens */
    max-width: 400px; /* Limit maximum width */
  }
}





/* Circular Images Section */
.circular-images-section {
  text-align: center;
  margin-top: 60px;
  padding: 40px 20px;
  background-color: #f9f9f9; /* Light background for the section */
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.circular-images-section h2 {
  font-size: 2rem;
  color: #2d2d2d;
  margin-bottom: 30px;
  position: relative;
}

.circular-images-section h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background-color: #007bff;
  margin: 10px auto 0;
  border-radius: 2px;
}

.circular-images-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.circular-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.circular-image:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.circular-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Responsive Design */
@media (max-width: 768px) {
  .circular-images-section h2 {
    font-size: 1.75rem;
  }

  .circular-image {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 480px) {
  .circular-images-section h2 {
    font-size: 1.5rem;
  }

  .circular-image {
    width: 80px;
    height: 80px;
  }

  .circular-images-container {
    gap: 20px;
  }
}





/* FAQ Section */
.faq-section {
  font-family: 'Roboto', sans-serif;
  margin: 60px auto;
  padding: 20px;
  max-width: 900px;
  background-color: #f4f4f4;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.faq-title {
  text-align: center;
  font-size: 2rem;
  color: #2d2d2d;
  margin-bottom: 30px;
  position: relative;
}

.faq-title::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background-color: #007bff;
  margin: 10px auto 0;
  border-radius: 2px;
}

.faqs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.faq-card.open {
  background-color: #e0ffe1; /* Light green for open card */
}

.faq-question {
  font-size: 1.15rem;
  color: #333;
  margin: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: color 0.3s ease;
}

.faq-question .arrow {
  font-size: 1.2rem;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.faq-card.open .faq-question .arrow {
  transform: rotate(180deg);
}

.faq-answer {
  font-size: 1rem;
  color: #666;
  margin-top: 15px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.faq-card.open .faq-answer {
  max-height: 200px; /* Adjust as needed */
  opacity: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .faq-section {
    padding: 10px;
  }

  .faq-title {
    font-size: 1.5rem;
  }

  .faq-question {
    font-size: 1rem;
  }

  .faq-answer {
    font-size: 0.9rem;
  }
}