/* General Styles */
.services-container {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
  text-align: center;
}

.services-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1.5rem;
}

.services-intro {
  max-width: 800px;
  margin: 0 auto 3rem auto;
}

.services-description {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.8;
}

/* Services List */
.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.service-card {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.service-icon {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 1rem;
}

.service-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
}

.service-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.service-benefits {
  list-style: none;
  padding: 0;
  text-align: left;
}

.service-benefits li {
  font-size: 0.95rem;
  color: #555;
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.5rem;
}

.service-benefits li::before {
  content: "•";
  color: #007bff;
  font-size: 1.2rem;
  position: absolute;
  left: 0;
  top: -2px;
}

/* Testimonials Section */
.testimonials {
  background-color: #fff;
  padding: 3rem 2rem;
  margin: 4rem 0;
}

.testimonials-title {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 2rem;
}

.testimonial-card {
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  text-align: left;
}

.testimonial-text {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.testimonial-author {
  font-size: 0.9rem;
  color: #007bff;
  font-weight: 600;
}

/* Call to Action Section */
.cta-container {
  background-color: #2a7293;
  padding: 3rem 2rem;
  border-radius: 10px;
  color: #fff;
}

.cta-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.cta-text {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}



/* Responsive Design */
@media (max-width: 768px) {
  .services-title {
    font-size: 2rem;
  }

  .service-card {
    padding: 1.5rem;
  }

  .testimonials {
    padding: 2rem 1rem;
  }

  .cta-container {
    padding: 2rem 1rem;
  }

  .cta-title {
    font-size: 1.75rem;
  }

  .cta-text {
    font-size: 1rem;
  }
}