* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.8); /* Glassmorphism effect */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0; /* Stick to the top */
  z-index: 1000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Subtle border */
}

/* Ensure the parent container allows sticky behavior */
.app {
  font-family: 'Poppins', sans-serif;
  color: #333;
  overflow-x: visible; /* Allow sticky behavior */
  min-height: 100vh; /* Ensure the parent container has enough height */
  display: flex;
  flex-direction: column;
}

.logo-img {
  height: 60px;
  width: auto;
  transition: transform 0.3s ease;
}

.logo-img:hover {
  transform: scale(1.05);
}

/* Nav Links */
.nav-links {
  display: flex;
  gap: 1px;
  align-items: center;
  transition: all 0.3s ease;
}

.nav-item {
  position: relative;
}

.nav-link {
  text-decoration: none;
  color: #333333;
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px; /* Gap for icons */
}

.nav-link:hover,
.nav-link:active,
.nav-link:focus {
  background: rgba(0, 123, 255, 0.1); /* Light blue background on hover */
  color: #007bff;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2); /* Soft shadow */
}

/* Sub-Menu Styles */
.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: rgba(255, 255, 255, 0.9); /* Glassmorphism effect */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px; /* Rounded corners */
  padding: 10px 0;
  z-index: 1000;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3-column layout */
  gap: 10px;
  min-width: 600px;
  max-height: 400px;
  overflow-y: auto; /* Scrollable if content overflows */
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
}

/* Custom Scrollbar for Sub-Menu */
.sub-menu::-webkit-scrollbar {
  width: 8px;
}

.sub-menu::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 0.5); /* Light track */
  border-radius: 4px;
}

.sub-menu::-webkit-scrollbar-thumb {
  background: rgba(0, 123, 255, 0.8); /* Blue scrollbar */
  border-radius: 4px;
}

.sub-menu::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 123, 255, 1); /* Darker blue on hover */
}

.nav-item:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.sub-link {
  text-decoration: none;
  color: #333333;
  font-size: 0.9rem;
  padding: 8px 16px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px; /* Gap for icons */
  border-radius: 8px;
}

.sub-link:hover,
.sub-link:active,
.sub-link:focus {
  background: rgba(0, 123, 255, 0.1); /* Light blue background on hover */
  color: #007bff;
  padding-left: 20px;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2); /* Soft shadow */
}

/* Hamburger Menu */
.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  background: none;
  border: none;
  padding: 5px;
}

.menu-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: #000000;
  margin: 3px 0;
  border-radius: 2px;
  transition: all 0.3s ease;
}

.menu-toggle.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-toggle.open .bar:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 20px;
  }

  .nav-links {
    position: absolute;
    top: 70px;
    right: 0;
    background: rgba(255, 255, 255, 0.9); /* Glassmorphism effect */
    backdrop-filter: blur(10px); /* Frosted glass effect */
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }

  .nav-links.open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .nav-item {
    width: 100%;
  }

  .nav-link {
    width: 100%;
    padding: 10px;
  }

  .sub-menu {
    position: static;
    box-shadow: none;
    padding-left: 20px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.3s ease;
    grid-template-columns: 1fr; /* Single column for mobile */
    max-height: 300px; /* Adjust max height for mobile */
  }

  .nav-item:hover .sub-menu,
  .nav-item:focus-within .sub-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .menu-toggle {
    display: flex;
  }
}

/* Dropdown Icon */
.dropdown-icon {
  color: black; /* Ensure the dropdown icon is black */
  transition: transform 0.3s ease;
}

.nav-item:hover .dropdown-icon {
  transform: rotate(180deg); /* Rotate icon on hover */
}


/* General Footer Styles */
.footer {
  background: rgb(36, 36, 103);
  color: #ffffff;
  padding: 50px 20px 20px;
  font-family: "Poppins", sans-serif;
  position: relative;
  overflow: hidden;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(71, 70, 70);
  backdrop-filter: blur(10px);
  z-index: -1;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 30px;
  position: relative;
  z-index: 1;
}

.footer-brand,
.footer-links,
.footer-resources,
.footer-contact {
  flex: 1;
  min-width: 250px;
  margin-bottom: 20px;
}

.footer-logo {
  width: 100px;
  margin-bottom: 20px;
  border-radius: 5000px;
}

.footer-description {
  font-size: 0.9rem;
  line-height: 1.6;
  opacity: 0.8;
  margin-bottom: 20px;
}

.footer-newsletter h4 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #228a20;
}

.newsletter-form {
  display: flex;
  gap: 10px;
}

.newsletter-form input {
  flex: 1;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 0.9rem;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.newsletter-form input:focus {
  border-color: #6c63ff;
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
}

.newsletter-form button {
  padding: 12px 20px;
  background-color: #228a20;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.newsletter-form button:hover {
  background-color: #574fdb;
  transform: translateY(-2px);
}

.footer-links h4,
.footer-resources h4,
.footer-contact h4 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #228a20;
}

.footer-links ul,
.footer-resources ul {
  list-style: none;
  padding: 0;
}

.footer-links li,
.footer-resources li {
  margin-bottom: 10px;
}

.footer-links a,
.footer-resources a {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-links a:hover,
.footer-resources a:hover {
  color: #63ff6b;
  transform: translateX(5px);
}

.contact-info {
  margin-bottom: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.contact-item i {
  color: #ffffff;
}

.social-links h5 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #228a20;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  color: #ffffff;
  font-size: 1.2rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
  color: #f7ff63;
  transform: translateY(-3px);
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(245, 255, 253, 0.1);
  font-size: 0.9rem;
  opacity: 0.8;
}

.footer-bottom a {
  color: #ffcc24;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-bottom a:hover {
  color: #5aff9f;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-brand,
  .footer-links,
  .footer-resources,
  .footer-contact {
    width: 100%;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-form button {
    width: 100%;
  }

  .social-icons {
    justify-content: center;
  }
}




/* Contact Actions Container */
.contact-actions {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

/* WhatsApp Icon Styling */
.whatsapp-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366; /* WhatsApp Green */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  color: #ffffff; /* White icon color */
  font-size: 24px; /* Icon size */
  text-decoration: none;
  position: relative;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
  animation: pulse 2s infinite; /* Pulse animation */
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
  }
}

/* Hover Effect */
.whatsapp-icon:hover {
  transform: scale(1.1); /* Slightly scale up */
  background-color: #128c7e; /* Darker WhatsApp Green */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3), 0 0 20px rgba(37, 211, 102, 0.5); /* Enhanced glow */
  animation: none; /* Disable pulse animation on hover */
}

/* Tooltip Styling */
.whatsapp-icon .tooltip {
  position: absolute;
  bottom: 70px; /* Position above the icon */
  right: 50%;
  transform: translateX(50%);
  background-color: #333333;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.whatsapp-icon:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .contact-actions {
    bottom: 15px;
    right: 15px;
  }

  .whatsapp-icon {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  .whatsapp-icon .tooltip {
    font-size: 12px;
    bottom: 60px;
  }
}




