/* CertificateVerification.css */

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

.certificate-verification-container {
  max-width: 700px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
}

.heading {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

.certificate-input {
  width: 100%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  box-sizing: border-box;
  margin-bottom: 20px;
  transition: border-color 0.3s;
}

.certificate-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.verify-btn {
  background: #007bff;
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-weight: bold;
}

.verify-btn:hover {
  background: #0056b3;
  transform: scale(1.05);
}

.verify-btn:disabled {
  background: #f14f4f;
  cursor: not-allowed;
}

.error-message {
  margin-top: 15px;
  font-size: 0.9rem;
  color: #d9534f;
}

/* Responsive Design */
@media (max-width: 768px) {
  .certificate-verification-container {
    margin: 20px;
    padding: 15px;
  }

  .heading {
    font-size: 1.5rem;
  }

  .certificate-input {
    height: 60px;
  }

  .verify-btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}



.faq-section {
  margin-top: 40px;
  text-align: left;
}

.faq {
  margin-bottom: 20px;
}

.faq h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.faq p {
  font-size: 1rem;
  color: #666;
}