/* WebDevelopment.css */
.course-details {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .course-details h1 {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .course-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .course-info {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .course-info h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .course-info p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  
  .price-tag {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .price-tag h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .price-tag .old-price {
    text-decoration: line-through;
    color: #888;
    margin-right: 10px;
  }
  
  .price-tag .new-price {
    color: #28a745;
    font-weight: bold;
  }
  
  .price-tag .discount {
    font-size: 1rem;
    color: #28a745;
    margin: 0;
  }
  
  .price-tag .discount span {
    font-weight: bold;
  }
  
  .course-duration {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .course-duration .duration-icon {
    color: #007bff;
    margin-right: 10px;
  }
  
  .course-duration p {
    font-size: 1rem;
    color: #555;
  }
  
  .instructor-details {
    margin-bottom: 20px;
  }
  
  .instructor-details h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .instructor-info {
    display: flex;
    align-items: center;
  }
  
  .instructor-info img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .instructor-info h4 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  .instructor-info p {
    font-size: 1rem;
    color: #555;
  }
  
  .highlights-list {
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 20px;
  }
  
  .highlights-list li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .highlights-list .highlight-icon {
    color: #28a745;
    margin-right: 10px;
  }
  
  .course-content .module {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .course-content .module:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .course-content .module-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  .course-content .module-header h4 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  .course-content .module-header span {
    font-size: 1.2rem;
    color: #333;
  }
  
  .course-content .module-details {
    padding: 10px;
    background-color: #fff;
  }
  
  .course-content .module-details p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .course-content .module-details ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .course-content .module-details li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
  }
  
  .benefits-list {
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 20px;
  }
  
  .benefits-list li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .benefits-list .benefit-icon {
    color: #28a745;
    margin-right: 10px;
  }
  
  .testimonials {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .testimonial {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .testimonial-rating {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .testimonial-rating .star-icon {
    color: #ffc107;
  }
  
  .testimonial h4 {
    font-size: 1.1rem;
    color: #333;
    text-align: right;
  }
  
  .faqs .faq {
    margin-bottom: 15px;
  }
  
  .faqs .faq h4 {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
  
  .faqs .faq-icon {
    color: #007bff;
    margin-right: 10px;
  }
  
  .faqs .faq p {
    font-size: 1rem;
    color: #555;
  }
  
  .action-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .apply-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .apply-button:hover {
    background-color: #0056b3;
  }