/* General Styles */
.jobs-container {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
  text-align: center;
}

.jobs-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 2rem;
}

.job-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.job-card {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.job-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #007bff;
  margin-bottom: 1rem;
}

.job-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.job-location {
  font-size: 0.95rem;
  color: #777;
  margin-bottom: 1.5rem;
}

.job-section {
  margin-bottom: 1.5rem;
}

.job-section h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
}

.job-section ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.job-section li {
  font-size: 0.95rem;
  color: #555;
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.5rem;
}

.job-section li::before {
  content: "•";
  color: #007bff;
  font-size: 1.2rem;
  position: absolute;
  left: 0;
  top: -2px;
}

.apply-btn {
  background-color: #007bff;
  color: #fff;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-btn:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .jobs-title {
    font-size: 2rem;
  }

  .job-card {
    padding: 1.5rem;
  }

  .job-title {
    font-size: 1.5rem;
  }
}