/* Hr.css */
.job-details {
    padding: 20px;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .role-selection {
    margin-bottom: 20px;
  }
  
  .role-selection label {
    margin-right: 10px;
    font-size: 14px;
  }
  
  .role-selection select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .job-detail-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .job-info {
    text-align: left;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .job-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .job-info p {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .job-highlights {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .job-highlights li {
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .skills-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 20px;
  }
  
  .skill-card {
    background: #007bff;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 12px;
  }
  
  .responsibilities-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .responsibilities-list li {
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .benefits-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .benefits-list li {
    font-size: 14px;
    margin-bottom: 8px;
    padding-left: 20px;
    position: relative;
  }
  
  .benefits-list li::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #007bff;
  }
  
  .apply-button {
    background: #007bff;
    color: white;
    padding: 12px 24px;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    transition: background 0.3s;
  }
  
  .apply-button:hover {
    background: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .job-info h2 {
      font-size: 20px;
    }
  
    .job-info p {
      font-size: 12px;
    }
  
    .job-highlights li {
      font-size: 12px;
    }
  
    .skill-card {
      font-size: 10px;
    }
  
    .responsibilities-list li {
      font-size: 12px;
    }
  
    .benefits-list li {
      font-size: 12px;
    }
  
    .apply-button {
      padding: 10px 20px;
      font-size: 12px;
    }
  }